import React, { useEffect, useState } from 'react';
import './order-details-item.scss';
import { OrderLineDetails } from '@services/oms-service/oms-service';
import { OrderDetailsContent } from '@views/order-details-view/hooks/use-order-details-content';
import { SecondaryButton } from '@common/index';
import { INSTALL_LINES_ID, LinkTargetValues } from '@constants';
import { OrderStatusContent } from '@views/order-details-view/hooks/use-nvc-order-status-content';
import {
    NVC_GREEN_STATUSES,
    NVC_ORDER_STATUS_MAP,
    NVC_RED_STATUSES,
    OrderStatusOption,
    transformDeliveryMethod,
} from '@views/order-details-view/nvc-status-map';
import { useAnalytics } from '@/hooks/use-analytics';

export interface OrderDetailsItemProps {
    itemData: OrderLineDetails;
    orderDetailsContent: OrderDetailsContent;
    orderStatusContent: OrderStatusContent;
    returnDisabled?: boolean;
    return?: boolean;
}

const installLinesIcon = './icons/installation-item--icon.svg';

const OrderDetailsItem = (props: OrderDetailsItemProps) => {
    const [fireAnalytics] = useAnalytics();
    const formattedProductName: string = props.itemData.item.itemShortDesc
        .toLowerCase()
        .split(' ')
        .join('-')
        .replace(/[^a-z0-9-]/g, '')
        .replace(/-{2,}/g, '-');
    const productPageUrl = props.itemData.item.itemShortDesc
        ? `https://www.nvc-pp.ford.com/product/${formattedProductName}?partNumber=${props.itemData.item.itemId}`
        : 'https://accessories.ford.com';
    const red = '#f9e8ea';
    const green = '#e5f3e6';
    const gray = '#e6e8ea';
    const deliveryMethod: string = props.itemData.deliveryMethod;
    const orderStatus: string = props.itemData.status.toLowerCase();
    const [isInstallIcon, setIsInstallIcon] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<string>(
        `https://accessories.ford.com/s3/${props.itemData.item.itemId}_0.jpg`
    );

    const chooseStatusBackgroundColor = (status: string): string => {
        if (NVC_GREEN_STATUSES.includes(status)) {
            return green;
        } else if (NVC_RED_STATUSES.includes(status)) {
            return red;
        }

        return gray;
    };

    const generateImageSrc = (): void => {
        if (props.itemData.item.itemId === INSTALL_LINES_ID) {
            setIsInstallIcon(true);
            setImageSrc(installLinesIcon);
        }
    };

    const determineDisplayStatus = (status: string): string => {
        let displayStatus = '';
        const deliveryMethodTransformed: string =
            transformDeliveryMethod(deliveryMethod);

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].forEach(
            (statusOption: OrderStatusOption) => {
                if (statusOption.options.includes(status.toLowerCase())) {
                    displayStatus = props.orderStatusContent[statusOption.name];
                }
            }
        );

        return displayStatus;
    };

    useEffect(() => {
        generateImageSrc();
    }, []);

    return (
        <div
            className="order-details-item__container"
            data-testid="order-details-item__container"
        >
            {props.return ? (
                <>
                    <div
                        className={`item__status`}
                        data-testid="item-status"
                        style={{
                            backgroundColor:
                                chooseStatusBackgroundColor(orderStatus),
                        }}
                    >
                        {!isInstallIcon && (
                            <p>{determineDisplayStatus(orderStatus)}</p>
                        )}
                    </div>
                    <div
                        className={
                            props.returnDisabled
                                ? 'return-disabled-grayscale'
                                : 'return-items'
                        }
                    >
                        {imageSrc && (
                            <>
                                {isInstallIcon ? (
                                    <img
                                        src={imageSrc}
                                        className="item__image install-icon-sizing"
                                        alt="item image"
                                        data-testid="item-image"
                                    />
                                ) : (
                                    <a
                                        href={productPageUrl}
                                        target={LinkTargetValues.BLANK}
                                    >
                                        <img
                                            src={imageSrc}
                                            className="item__image"
                                            alt="item image"
                                            data-testid="item-image"
                                        />
                                    </a>
                                )}
                            </>
                        )}

                        <div className="item__info-container">
                            {isInstallIcon ? (
                                <p
                                    className="item__title"
                                    data-testid="item-title"
                                >
                                    {props.itemData.item.itemShortDesc.substring(
                                        0,
                                        50
                                    ) || '-'}
                                </p>
                            ) : (
                                <a
                                    className="item__title"
                                    data-testid="item-title"
                                    href={productPageUrl}
                                    target={LinkTargetValues.BLANK}
                                >
                                    {props.itemData.item.itemShortDesc.substring(
                                        0,
                                        50
                                    ) || '-'}
                                </a>
                            )}
                            <p className="item__id" data-testid="item-id">
                                {props.itemData.item.itemId}
                            </p>
                            <p className="item__price" data-testid="item-price">
                                ${props.itemData.lineOverallTotals.unitPrice}
                            </p>
                            <p
                                className="item__quantity"
                                data-testid="item-quantity"
                            >
                                {props.orderDetailsContent.quantity}{' '}
                                {Math.floor(
                                    Number(props.itemData.originalOrderedQty)
                                )}
                            </p>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div
                        className={`item__status`}
                        data-testid="item-status"
                        style={{
                            backgroundColor:
                                chooseStatusBackgroundColor(orderStatus),
                        }}
                    >
                        {!isInstallIcon && (
                            <p>{determineDisplayStatus(orderStatus)}</p>
                        )}
                    </div>
                    {imageSrc && (
                        <>
                            {' '}
                            {isInstallIcon ? (
                                <img
                                    src={imageSrc}
                                    className="item__image install-icon-sizing"
                                    alt="item image"
                                    data-testid="item-image"
                                />
                            ) : (
                                <a
                                    href={productPageUrl}
                                    target={LinkTargetValues.BLANK}
                                >
                                    <img
                                        src={imageSrc}
                                        className="item__image"
                                        alt="item image"
                                        data-testid="item-image"
                                    />
                                </a>
                            )}
                        </>
                    )}
                    <div className="item__info-container">
                        {isInstallIcon ? (
                            <p className="item__title" data-testid="item-title">
                                {props.itemData.item.itemShortDesc.substring(
                                    0,
                                    50
                                ) || '-'}
                            </p>
                        ) : (
                            <a
                                className="item__title"
                                data-testid="item-title"
                                href={productPageUrl}
                                target={LinkTargetValues.BLANK}
                            >
                                {props.itemData.item.itemShortDesc.substring(
                                    0,
                                    50
                                ) || '-'}
                            </a>
                        )}

                        <p className="item__id" data-testid="item-id">
                            {props.itemData.item.itemId}
                        </p>
                        <p className="item__price" data-testid="item-price">
                            ${props.itemData.lineOverallTotals.unitPrice}
                        </p>
                        <p
                            className="item__quantity"
                            data-testid="item-quantity"
                        >
                            {props.orderDetailsContent.quantity}{' '}
                            {Math.floor(
                                Number(props.itemData.originalOrderedQty)
                            )}
                        </p>
                    </div>
                    <div className="item__buttons-container">
                        {!isInstallIcon && (
                            <SecondaryButton
                                className="buy-again-button"
                                data-testid="buy-again-button"
                                linkTarget={LinkTargetValues.BLANK}
                                link={productPageUrl}
                                internal={false}
                                onClick={() => {
                                    fireAnalytics(
                                        'orderDetailsBuyAgainOnclickEvent'
                                    );
                                }}
                            >
                                {props.orderDetailsContent.buyAgain}
                            </SecondaryButton>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default OrderDetailsItem;
