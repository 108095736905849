import { PrimaryButton } from '@/components/common';
import DropdownTemplate from '@/components/common/dropdown-template/dropdown-template';
import randomNumberUtil from '@/components/utils/random-number-util/random-number-util';
import { useExperienceContent } from '@/hooks/use-server-content';
import { ExperienceFragmentModel } from '@/services/content-service/content-service.interface';
import OmsService, {
    OrderLineDetails,
} from '@/services/oms-service/oms-service';
import OrderDetailsItem from '@/views/order-details-view/components/order-details-item/order-details-item';
import { useOrderStatusContent } from '@/views/order-details-view/hooks/use-nvc-order-status-content';
import { useOrderDetailsContent } from '@/views/order-details-view/hooks/use-order-details-content';
import {
    NVC_ORDER_STATUS_MAP,
    OrderStatusOption,
    transformDeliveryMethod,
} from '@/views/order-details-view/nvc-status-map';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { NotificationType } from '@contexts/notificationContext';
import { Notification } from '@sections/account-portal/components/notification-message/notification';
import CreateReturnModal from '../create-return-modal/create-return-modal';
import { findPathByAlias } from '@/routes/routesList';
import HttpService from '@/services/http-service/http-service';
import { ActivityIndicator } from '@/components/common/activity-indicator/activity-indicator';

export interface ReasonDropdownContent extends ExperienceFragmentModel {
    dropdownOptions: ReasonOptions[];
    ':type': string;
}

export interface ReasonOptions {
    returnReasonLabel: string;
    returnReasonValue: string;
}

const CreateReturnComponent = () => {
    const [reasonValue, setReasonValue] = useState('');
    const [showError, setShowError] = useState<boolean>(false);
    const [isItemReturnLoading, setIsItemReturnLoading] =
        useState<boolean>(false);
    const [experienceFragmentReasonDropdownContent] =
        useExperienceContent<ReasonDropdownContent>(
            'common',
            'return-reason',
            'orderreturnreasondro'
        );
    const [orderDetailsItems, setOrderDetailsItems] =
        useState<OrderLineDetails[]>(null);
    const [selectedItems, setSelectedItems] = useState<OrderLineDetails[]>([]);
    const createReturnContent = useOrderDetailsContent();

    const dropdownTemplateContent = [];
    experienceFragmentReasonDropdownContent?.dropdownOptions.map((item) =>
        dropdownTemplateContent.push(item.returnReasonValue)
    );
    const location = useLocation();
    const navigate = useNavigate();
    const orderDetailsData = location.state?.orderDetails;
    const deliveryMethod: string =
        orderDetailsData?.orderLine[0]?.deliveryMethod;
    const orderHeaderKey = orderDetailsData?.orderHeaderKey;
    const orderDetailsContent = useOrderDetailsContent();
    const orderStatusContent = useOrderStatusContent();
    const [modalState, setModalState] = useState<boolean>(false);
    const omsService = new OmsService(HttpService);
    const [values, setValues] = useState([
        {
            name: 'select-return',
            value: 'select-return',
            isChecked: null,
            displayName: '',
        },
    ]);

    const handleItemSelection = (item: OrderLineDetails) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter(
                    (selectedItem) => selectedItem !== item
                );
            } else {
                return [...prevSelectedItems, item];
            }
        });
    };

    const sortItems = (): OrderLineDetails[] => {
        let finalItems: OrderLineDetails[] = [];
        const deliveryMethodTransformed =
            transformDeliveryMethod(deliveryMethod);

        NVC_ORDER_STATUS_MAP[deliveryMethodTransformed].forEach(
            (statusOption: OrderStatusOption) => {
                const filteredList = orderDetailsData.orderLine.filter((item) =>
                    statusOption.options.includes(item.status?.toLowerCase())
                );

                if (filteredList.length) {
                    finalItems = [...finalItems, ...filteredList];
                }
            }
        );

        return finalItems;
    };

    const sortedReturnableItems = orderDetailsItems?.sort((a, b) => {
        if (
            a.isStorefrontReturnable === 'Y' &&
            b.isStorefrontReturnable !== 'Y'
        ) {
            return -1;
        } else if (
            a.isStorefrontReturnable !== 'Y' &&
            b.isStorefrontReturnable === 'Y'
        ) {
            return 1;
        }
        return 0;
    });

    const orderLine = selectedItems.map((item) => ({
        orderLineKey: item.orderLineKey,
        orderedQty: parseFloat(item.originalOrderedQty).toFixed(1),
        returnReason: reasonValue,
    }));

    const requestBody = {
        orderLine: orderLine,
    };

    const nagivateToConfirmation = async () => {
        setSelectedItems([]);
        setModalState(false);
        setIsItemReturnLoading(true);
        await omsService
            .createReturn(requestBody, orderHeaderKey)
            .then((createReturn) => {
                if (!createReturn) {
                    setShowError(true);
                } else {
                    navigate(findPathByAlias('ReturnConfirmationView'), {
                        state: {
                            returnResponseContent: createReturn,
                        },
                    });
                }
            });
    };

    const handleClose = async () => {
        setSelectedItems([]);
        setModalState(false);
    };

    const handleError = (errorFlag: boolean) => {
        setShowError(errorFlag);
    };

    useEffect(() => {
        const updateValues: any[] = [...values];
        setValues(updateValues);
        if (orderDetailsData && orderDetailsData.orderLine.length) {
            setOrderDetailsItems(sortItems());
        }
    }, [orderDetailsData, setReasonValue, reasonValue, showError]);

    return (
        <>
            <CreateReturnModal
                isModalVisible={modalState}
                modalContentFragments={orderDetailsContent}
                selectedItems={selectedItems}
                handleClose={handleClose}
                orderDetails={orderDetailsData}
                returnReason={reasonValue}
                onError={handleError}
                onConfirm={nagivateToConfirmation}
            />
            <div
                className="create-return-component-container"
                data-test-id="create-return-component"
            >
                {showError && (
                    <div
                        className={`create-return-component-notification-container`}
                        data-testid={`create-return-component-notification-container`}
                    >
                        <Notification
                            status={NotificationType.Error}
                            mainCopy={
                                createReturnContent.createReturnErrorMessage
                            }
                            hideBorder={true}
                            hideAfterTimeout={false}
                            onHideNotification={() => {
                                setShowError(false);
                            }}
                        />
                    </div>
                )}

                <h1 className="create-return-component-title">
                    {createReturnContent?.returnspageHeader ||
                        'Select Items to Return'}
                </h1>
                <div className="create-return-component-items">
                    {orderStatusContent &&
                        sortedReturnableItems?.map((item: OrderLineDetails) => {
                            const randomNumberAsKey = randomNumberUtil
                                .getRandomNumber()
                                .toFixed(4);

                            return (
                                <>
                                    <div className="create-return-component-checkbox-container">
                                        {item.isStorefrontReturnable ===
                                            'Y' && (
                                            <div className="create-return-component-user-input">
                                                <input
                                                    type="checkbox"
                                                    disabled={false}
                                                    className="create-return-component-user-checkbox"
                                                    checked={selectedItems.includes(
                                                        item
                                                    )}
                                                    onChange={() =>
                                                        handleItemSelection(
                                                            item
                                                        )
                                                    }
                                                />
                                            </div>
                                        )}
                                        <OrderDetailsItem
                                            key={randomNumberAsKey}
                                            itemData={item}
                                            orderDetailsContent={
                                                orderDetailsContent
                                            }
                                            orderStatusContent={
                                                orderStatusContent
                                            }
                                            returnDisabled={
                                                item.isStorefrontReturnable ===
                                                'N'
                                            }
                                            return={true}
                                        />
                                    </div>
                                </>
                            );
                        })}
                </div>
                <hr className="hr-line" aria-hidden={true} />
                <div className="create-return-component-reason-box">
                    <DropdownTemplate
                        DropdownTemplateContent={dropdownTemplateContent}
                        field={''}
                        selectedItem={reasonValue}
                        setSelectedItem={setReasonValue}
                        dropdownTemplateLabel={
                            createReturnContent.returnsDropdown
                        }
                    ></DropdownTemplate>
                    <PrimaryButton
                        testId={'create-return-component-continue-button'}
                        onClick={() => {
                            setModalState(true);
                        }}
                    >
                        {createReturnContent?.continue || 'continue'}
                    </PrimaryButton>
                </div>
                <div className="create-return-component-faq-container">
                    <div className="create-return-component-faq-title">
                        {orderDetailsContent.faqTitle}
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: orderDetailsContent.faqItem
                                ? orderDetailsContent.faqItem
                                : '',
                        }}
                        className="create-return-component-faq-item"
                    ></div>
                </div>
            </div>
            {isItemReturnLoading && (
                <ActivityIndicator className={'full-height'} />
            )}
        </>
    );
};

export default CreateReturnComponent;
